import { React } from "react";
import { useTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';


const EntradasEventos = ({ lang, ruta, noticias }) => {
    const { t } = useTranslation();

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    const ellocale = lang + "-" + lang.toUpperCase();

    var options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    };

    return (
        <>
            <section className="blog-page section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-sm-12 col-xs-12">
                            {noticias.map((noticia, index) => (
                                <div className="post-slide-blog" key={index}>
                                    <div className="blog-img">
                                        {
                                            noticia.imagen != ""
                                                ? <img alt="image" src={ruta + "/blog/" + noticia.id + "/" + noticia.imagen} className="img-fluid" />
                                                : <img alt="image" src="https://picsum.photos/842/438" className="img-fluid" />
                                        }
                                        <a href="#">
                                            {new Date(noticia.fecha_publica).toLocaleDateString(ellocale, options)}
                                        </a>
                                        <span><i className="fa-regular fa-user"></i> weRespect</span>
                                    </div>
                                    <h2><a href={"/" + preidioma + t("eventos") + "/" + noticia.laurl}>{noticia.eltitulo}</a></h2>

                                    <p>{ReactHtmlParser(noticia.elsubtitulo)}</p>

                                    <a href={"/" + preidioma + t("eventos") + "/" + noticia.laurl} className="srex-btn srex-btn--outline">
                                        {t("Ver más")}<i className="fa-solid fa-plus"></i>
                                    </a>
                                </div>

                            ))
                            }
                        </div>
                        <div className="col-lg-4 col-sm-12 col-xs-12">
                            <div className="latest_blog wow fadeInRight">
                                <h4 className="blog_sidebar_title">{t("Ultimos eventos")}</h4>
                                {noticias.map((noticia, index) => (
                                    <div className="single_latest_blog" key={index} >
                                        {
                                            noticia.imagen != ""
                                                ? <img alt="image" src={ruta + "/blog/" + noticia.id + "/" + noticia.imagen} />
                                                : <img alt="image" src="https://picsum.photos/90/94" />
                                        }
                                        <span><i className="fa-regular fa-calendar-days"></i> {new Date(noticia.fecha_publica).toLocaleDateString(ellocale, options)}</span>
                                        <a href={"/" + preidioma + t("eventos") + "/" + noticia.laurl}>{noticia.eltitulo}</a>
                                    </div>
                                ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>

    );
};
export default EntradasEventos;
