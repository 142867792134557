import { React } from "react";
import { useTranslation } from 'react-i18next';

const TextoSobreNosotros = ({ ruta, lang, sobrenosotros }) => {
    const { t } = useTranslation();

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    var imagen = "/images/web/bedloop-nofoto.png";

    if (sobrenosotros.imagen_cabecera) {
        imagen = ruta + "/" + sobrenosotros.imagen_cabecera;
    }

    return (
        <>
            <section className="srex-about-us-one srex-section">
                <div className="srex-about-us-one__container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-12">
                            <div className="text-left srex-about-us-one__left">
                                <div className="srex-section__head">

                                    <div className="srex-section__head__desc wow ud-fade-in-up sobrenosaltres" data-wow-delay="200ms" dangerouslySetInnerHTML={{ __html: sobrenosotros.ladesc }}></div>
                                </div>
                            </div>
                        </div>
                        <div className="px-0 col-lg-6 col-12">
                            <div className="srex-about-us-one__right">
                                <img
                                    src={imagen}
                                    alt="About Us"
                                    className="srex-about-us-one__right__img wow ud-fade-in-up" data-wow-delay="200ms"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
export default TextoSobreNosotros;
