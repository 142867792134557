import { React } from "react";
import { useTranslation } from 'react-i18next';

const Sponsors = () => {
    const { t } = useTranslation();
    return (
        <>
            <div className="srex-sponsor srex-sponsor-three srex-section wow ud-fade-in-up" data-wow-delay="200ms">
                <div className="container">
                    <div className="srex-sponsor__items wow fadeInUp" data-wow-duration="1.5s">
                        <a href="#" className="srex-sponsor__items-item">
                            <img src="/images/web/werespect/ajuntament_barcelona_logo_blanco-01.png" alt="Sponsor One" />
                        </a>
                        <a href="#" className="srex-sponsor__items-item">
                            <img src="/images/web/werespect/airbnb_logo_blanco.png" alt="Sponsor Two" />
                        </a>
                        <a href="#" className="srex-sponsor__items-item">
                            <img src="/images/web/werespect/logo_horizontal_white_700.png" alt="Sponsor Three" />
                        </a>
                        <a href="#" className="srex-sponsor__items-item">
                            <img src="/images/web/werespect/logo-apartur-blanco-01.png" alt="Sponsor Four" />
                        </a>
                        <a href="#" className="srex-sponsor__items-item">
                            <img src="/images/web/werespect/madridaloja-blanco.png" alt="Sponsor Five" />
                        </a>
                        <a href="#" className="srex-sponsor__items-item">
                            <img src="/images/web/werespect/ajuntament_barcelona_logo_blanco-01.png" alt="Sponsor One" />
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Sponsors;
