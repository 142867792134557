import { useEffect } from "react";
import { useTranslation } from 'react-i18next';

const Scripts = ({ lang }) => {

    const { t } = useTranslation();

    const jqueryScript = "/vendor/werespect/jquery/jquery-3.6.0.min.js";

    const scriptSources = [
        "/vendor/werespect/bootstrap/js/popper.min.js",
        "/vendor/werespect/bootstrap/js/bootstrap.min.js",
        "/vendor/werespect/slick-slider/slick.js",
        "/vendor/werespect/wow/wow.min.js",
        "/vendor/werespect/metismenu/metismenu.js",
        "/vendor/werespect/magnific-popup/magnific-popup.min.js",
        "/vendor/werespect/waypoints/waypoints.min.js",
        "/vendor/werespect/counterup/counterup.js",
        "/vendor/werespect/jquery-mixitup/jquery-mixitup.min.js",
        "/vendor/werespect/custom.js",
    ];

    useEffect(() => {
        const jqueryScriptElement = document.createElement("script");
        jqueryScriptElement.src = jqueryScript;
        document.body.appendChild(jqueryScriptElement);

        jqueryScriptElement.onload = () => {
            const scripts = scriptSources.map((src) => {
                const script = document.createElement("script");
                script.src = src;
                document.body.appendChild(script);
                return script;
            });

            return () => {
                scripts.forEach((script) => document.body.removeChild(script));
            };
        }
    }, []);

    return null;
};

export default Scripts;
