import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import CustomAlert from "../Compartido/CustomAlert.jsx"

const TextoContacto = ({ lang, config }) => {
    const { t } = useTranslation();

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    const [alert, setAlert] = useState({ show: false, type: '', message: '' });

    const enviaContacto = (e) => {
        e.preventDefault()
        let token = document.getElementById('meta_token').getAttribute('content');

        const result = axios.post('/enviarcontacto', {
            yourname: e.target.name.value,
            contactEmail: e.target.email.value,
            contactMessage: e.target.message.value,
            enterprise: e.target.enterprise.value,
            Phone: e.target.phone.value
        }, {
            headers: {
                'content-type': 'text/json',
                'X-CSRF-TOKEN': token
            }
        })
            .then((response) => {
                if (response.data.type == 'success') {
                    setAlert({ show: true, type: 'ok', message: t("Contacto enviado. En breve nos pondremos en contacto con Ud.") });
                    setTimeout(() => setAlert({ show: false, type: '', message: '' }), 2000);

                    location.reload();
                }
                else {
                    setAlert({ show: true, type: 'ko', message: t("Contacto no enviado") });
                    setTimeout(() => setAlert({ show: false, type: '', message: '' }), 2000);
                }
            });
    }

    return (
        <section className="srex-contact--three srex-contact">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-12">
                        <div className="srex_contact_address wow ud-fade-in-up" data-wow-delay="200ms">
                            <div className="srex_contact_content">
                                <h4>- {t("Información")}</h4>
                                <h1>{t("Póngase en contacto")}</h1>
                            </div>
                            <div className="srex-single-address">
                                <div className="address_icon"><i className="fa-solid fa-location-dot"></i></div>
                                <h4>{t("Dirección")}</h4>
                                <p>{config.direccion_fiscal}<br />{config.poblacion_fiscal} </p>
                            </div>
                            <div className="srex-single-address">
                                <div className="address_icon"><i className="fa-solid fa-envelope"></i></div>
                                <h4>{t("Email")}</h4>
                                <p>{config.email_fiscal}</p>
                            </div>
                            <div className="srex-single-address">
                                <div className="address_icon"><i className="fa-solid fa-phone"></i></div>
                                <h4>{t("Teléfono")}</h4>
                                <p>{config.telefono_fiscal}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-12">
                        <div className="srex-contact__left srex_contact_address wow ud-fade-in-up" data-wow-delay="400ms">
                            <div className="srex_contact_content">
                                <h4>- {t("Mensaje")}</h4>
                                <h1>{t("Envíenos un mensaje")}</h1>
                            </div>
                            <form action="#" onSubmit={enviaContacto}>
                                <div className="row justify-content-center">
                                    <div className="col-md-12 col-12">
                                        <input name="full-name" placeholder={t("Su nombre")} type="text" required />
                                    </div>
                                    <div className="col-md-12 col-12">
                                        <input name="enterprise" placeholder={t("Empresa")} type="text" required />
                                    </div>
                                    <div className="col-md-12 col-12">
                                        <input name="email" placeholder={t("Dirección email")} type="text" required />
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-md-12 col-12">
                                        <input placeholder={t("Su teléfono")} type="text" name="phone" required />
                                    </div>
                                </div>
                                <div>
                                    <textarea placeholder={t("Su mensaje")} id="message" rows="5" name="message" required></textarea>
                                </div>
                                <div>
                                    <button type="submit" className="srex-btn srex-btn--secondary">{t("Enviar")}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {alert.show && (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                    <div style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: "50"
                    }}>
                        <CustomAlert type={alert.type} message={alert.message} onClose={handleCloseAlert} />
                    </div>
                </div>
            )}
        </section>
    );
};
export default TextoContacto;
