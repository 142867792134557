import React from "react";
import { useTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';

const TextoSello = ({ ruta, lang, comosello }) => {
    const { t } = useTranslation();

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    var imagen = "/images/web/bedloop-nofoto.png";

    if (comosello.imagen_cabecera) {
        imagen = ruta + "/" + comosello.imagen_cabecera;
    }

    return (
        <>
            <section className="srex-question-two sqp srex-section">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <div className="srex-question-two__left wow ud-fade-in-left" data-wow-delay="200ms">
                                <div className="srex-accordion accordion" id="faq">
                                    <div className="accordion-item">
                                        <div className="accordion-header">
                                            <div className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne" role="button">
                                                <div className="accordion-header__left">
                                                    <h3>{t("Control sobre el ruido")}</h3>
                                                </div>
                                                <i className="fa-solid fa-angle-down open"></i>
                                                <i className="fa-solid fa-angle-up close"></i>
                                            </div>
                                        </div>
                                        <div id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#faq">
                                            <div className="accordion-body">
                                                <p>{t("Se deberá acreditar la instalación de un sistema de monitorización certificado por weRespect que analice en tiempo real y sin interrupción los niveles acústicos dentro de la vivienda, y que avise ante cualquier evento que sea susceptible de generar molestias entre los vecinos.")}</p>
                                                <p>{t("Se deberá de disponer de un servicio externo de conserje nocturno que en caso de que se produzca un suceso potencialmente molesto, pueda responder a este y eliminarlo.")}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div className="accordion-header">
                                            <div className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" role="button">
                                                <div className="accordion-header__left">
                                                    <h3>{t("Cuidar el espacio compartido con vecinos")}</h3>
                                                </div>
                                                <i className="fa-solid fa-angle-down open"></i>
                                                <i className="fa-solid fa-angle-up close"></i>
                                            </div>
                                        </div>
                                        <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#faq">
                                            <div className="accordion-body">
                                                <p>{t("Etiquetar la capacidad máxima del ascensor en varios idiomas, como mínimo en los idiomas oficiales de la ciudad y en inglés, así como informar en la documentación contractual de la capacidad máxima del ascensor y las consecuencias de no respetarlas.")}</p>
                                                <p>{t("En caso de autorizarse por la comunidad de vecinos, se instalará una báscula de control de peso para evitar el bloqueo.")}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div className="accordion-header">
                                            <div className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseTwo" role="button">
                                                <div className="accordion-header__left">
                                                    <h3>{t("Señalización")}</h3>
                                                </div>
                                                <i className="fa-solid fa-angle-down open"></i>
                                                <i className="fa-solid fa-angle-up close"></i>
                                            </div>
                                        </div>
                                        <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#faq">
                                            <div className="accordion-body">
                                                <p>{t("Señalizar convenientemente el timbre de la vivienda turística para evitar confusiones y molestias entre los vecinos. En caso que la comunidad lo acepte, se deberá instalar un teclado eléctrico que elimine el uso de llaves físicas.")}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div className="accordion-header">
                                            <div className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseTwo" role="button">
                                                <div className="accordion-header__left">
                                                    <h3>{t("Cuidado del Medio Ambiente")}</h3>
                                                </div>
                                                <i className="fa-solid fa-angle-down open"></i>
                                                <i className="fa-solid fa-angle-up close"></i>
                                            </div>
                                        </div>
                                        <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#faq">
                                            <div className="accordion-body">
                                                {t("Se dispondrán en el apartamento cubos de separación de residuos o se entregaran bolas de separación de residuos y se entregara a los turistas información relacionada con la situación de los contenedores de reciclaje. Se indicará con carteles homologados por weRespect el necesario cuidado en el consumo de recursos como agua o electricidad.")}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="srex-question-two__right wow ud-fade-in-right" data-wow-delay="200ms">
                                {/* <img src="https://picsum.photos/600/600" alt="Question Img" />*/}
                                <img src="/images/web/werespect/sello-werespect2.png" alt="Question Img" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
export default TextoSello;
