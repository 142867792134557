import { React } from "react";
import { useTranslation } from 'react-i18next';

const Video = ({ lang }) => {
    const { t } = useTranslation();

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    return (
        <div className="srex-video srex-section">
            <div className="container-fluid">
                <div className="srex-video__box wow ud-fade-in-up" data-wow-delay="200ms">
                    <a
                        href="https://www.werespect.net/wp-content/uploads/2019/07/weRespect-video-recortado.mp4?_=1"
                        className="srex-video__play__btn popup-video"
                    >
                        <i className="fa-solid fa-play"></i>
                    </a>
                    <img src="/images/web/werespect/video-section.png" alt="Video" />
                </div>
            </div>
        </div>
    );
}

export default Video;
