import { React } from "react";
import { useTranslation } from 'react-i18next';

const TextoVentajas = ({ ruta, lang, ventajas }) => {
    const { t } = useTranslation();

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    var imagen = "/images/web/bedloop-nofoto.png";

    if (ventajas.imagen_cabecera) {
        imagen = ruta + "/" + ventajas.imagen_cabecera;
    }

    return (
        <>
            {/*<section className="srex-service srex-service--3 srex-section">
                <div className="container">
                    <div className="text-center srex-section__head wow ud-fade-in-up" data-wow-delay="200ms">
                        <h2 className="srex-section__head__title">
                            {t("Una vivienda certificada con el sello WERESPECT")}:
                        </h2>
                    </div>
                    <div className="srex-info-box">
                        <div className="row">
                            {ventajas.map((ventaja, index) => (
                                <div className="col-md-6 col-lg-12 col-12" key={index}>
                                    <div className="srex-info-box__item srex-info-box__item--3 srex-info-box__item--solar-pro wow ud-fade-in-up" data-wow-delay={200 * (index + 1) + "ms"}>

                                        <div className="srex-info-box__item__img">
                                            <i className="fa-solid fa-star-sharp"></i>
                                        </div>

                                        <h3 className="srex-info-box__item__text">
                                            {ventaja.ventaja_nombre}
                                        </h3>

                                    </div>
                                </div>
                            ))
                            }
                        </div>
                    </div>
                </div>
            </section>*/}


            <section className="srex-about-us-two sbut srex-section">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-12 col-lg-12">
                            <div className="srex-section__head wow ud-fade-in-up" data-wow-delay="200ms">
                                <h5 className="srex-section__head__badge">
                                    {t("Ventajas")}
                                </h5>
                                <div className="gap-5 d-flex align-content-center">
                                    <h2 className="srex-section__head__title" style={{ textTransform: "none" }}>
                                        {t("Una vivienda certificada con el sello weRespect")}:
                                    </h2>
                                </div>
                            </div>
                            <div className="srex-about-us-two__left-content wow ud-fade-in-up" data-wow-delay="200ms">
                                <div className="srex-about-us-two__left-content__img">
                                    <img
                                        src="/images/web/werespect/Ventajas.jpg"
                                        alt={t("ventajas")}
                                    />
                                </div>
                                <div className="srex-about-us-two__left-content__right wow ud-fade-in-up" data-wow-delay="200ms">
                                    <p>{t("Cada vez existe una mayor conciencia sobre la sostenibilidad social y ambiental de la actividad económica en general y del turismo en particular. Los negocios que adoptan medidas que promuevan unas prácticas más sostenibles se benefician de múltiples maneras, pero especialmente del reconocimiento cada vez mayor por parte de la sociedad y de sus propios clientes.")}</p>
                                    <div className="mt-3 srex-icon-list">
                                        <ul>
                                            {ventajas.map((ventaja, index) => (
                                                <li>
                                                    <i className="fa-solid fa-check"></i>
                                                    <p>
                                                        {ventaja.elnombre}
                                                    </p>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
export default TextoVentajas;
