import { React, useState } from "react";
import { useTranslation } from 'react-i18next';

const Blog = ({ lang, noticias, ruta }) => {
    const { t } = useTranslation();

    var preidioma = ""

    if (lang != 'es') preidioma = lang + "/";

    const ellocale = lang + "-" + lang.toUpperCase();

    var options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    };

    return (
        <>
            <section className="srex-blog-one srex-blog-two srex-section">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="srex-blog-one__title">
                                <div className="srex-section__head wow ud-fade-in-left" data-wow-delay="200ms">
                                    <h5 className="srex-section__head__badge wow fadeInLeft" data-wow-duration="0.3s">
                                        {t("Actualidad")}
                                    </h5>
                                    <h2 className="srex-section__head__title wow fadeInLeft" data-wow-duration="0.6s">
                                        {t("Consulte todas nuestras últimas publicaciones y próximos eventos")}
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {noticias.map((noticia, index) => (
                            <>
                                <div className="col-12 col-lg-4 col-md-6" key={index}>
                                    <div className="srex-blog-one__post srex-blog-two__post wow ud-fade-in-up" data-wow-delay={200 * (index + 1) + "ms"}>
                                        <a href={"/" + preidioma +
                                            (noticia.tags.includes("prensa") ? t("prensa") : noticia.tags.includes("eventos") ? t("eventos") : t("publicaciones"))
                                            + "/" + noticia.laurl} className="srex-blog-one__post__img">
                                            {
                                                noticia.imagen != ""
                                                    ? <img alt={noticia.tags} src={ruta + "/blog/" + noticia.id + "/" + noticia.imagen} />
                                                    : <img alt={noticia.tags} src="https://picsum.photos/410/270" />
                                            }
                                        </a>
                                        <div className="srex-blog-two__post__badge">
                                            <h6>{(noticia.tags.includes("prensa") ? t("Prensa") : noticia.tags.includes("eventos") ? t("eventos") : t("Publicaciones"))}</h6>
                                        </div>
                                        <div className="d-flex gap-5 mt-3">
                                            <div className="d-flex align-items-center gap-2">
                                                <i className="fa-solid fa-calendar-alt"></i>
                                                <h6>{new Date(noticia.fecha_publica).toLocaleDateString(ellocale, options)}</h6>
                                            </div>
                                        </div>
                                        <h3><a href={"/" + preidioma +
                                            (noticia.tags.includes("prensa") ? t("prensa") : noticia.tags.includes("eventos") ? t("eventos") : t("publicaciones"))
                                            + "/" + noticia.laurl}>{noticia.eltitulo}</a></h3>
                                        <div className="srex-info-box__more">
                                            <a href={"/" + preidioma +
                                                (noticia.tags.includes("prensa") ? t("prensa") : noticia.tags.includes("eventos") ? t("eventos") : t("publicaciones"))
                                                + "/" + noticia.laurl}>
                                                {t("Leer más")}
                                                <i className="fa-solid fa-plus"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ))
                        }
                    </div>
                </div>
            </section>
        </>
    );
}
export default Blog;
