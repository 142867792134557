import React from 'react';
import Header from './Compartido/header.jsx';
import Footer from './Compartido/Footer.jsx';
import Destacados from './Inicio/Destacados.jsx';
import Video from './Inicio/Video.jsx';
import Blog from './Inicio/Blog.jsx';
import Cabecera from './Inicio/Cabecera.jsx';
import AboutUs from './Inicio/AboutUs.jsx';
import OtrosDestacados from './Inicio/OtrosDestacados.jsx';
import Faqs from './Inicio/Faqs.jsx';
import Subscribe from './Inicio/Subscribe.jsx';
import Sponsors from './Inicio/Sponsors.jsx';
import Scripts from './Compartido/Scripts.jsx';


{/* Bootstrap CSS */ }
import 'bootstrap/dist/css/bootstrap.min.css';
{/* FontAwesome CSS */ }
{/* import "./assets/vendors/font-awesome/css/all.min.css";*/ }
{/*  Slick Slider CSS */ }
import "./../../../../css/web/werespect/slick-slider/slick.css";
{/* Slick Slider Theme */ }
import "./../../../../css/web/werespect/slick-slider/slick-theme.css";
{/*  MetisMenu CSS */ }
import "./../../../../css/web/werespect/metismenu/metismenu.css";
{/* Magnific Popup */ }
import "./../../../../css/web/werespect/magnific-popup/magnific-popup.css";
{/* Main Stylesheet */ }
import "./../../../../css/web/werespect/style.css";
{/* Portada Stylesheet */ }
import "./../../../../css/web/werespect/portada.css";

const Inicio = (props) => {

    return (
        <>
            {/* <body className="body-wrapper home-one" data-scroll-animation="true"> */}
            {/* Overlay */}
            <div className="overlay"></div>

            <Header
                lang={props.lang}
                ruta={props.ruta}
                config={props.websgrupo}
                meta_titulo={props.meta_titulo}
                meta_descripcion={props.meta_descripcion}
                meta_keywords={props.meta_keywords}
            />
            <Cabecera
                lang={props.lang}
            />
            <AboutUs
                lang={props.lang}
            />

            <OtrosDestacados
                lang={props.lang}
            />
            <Destacados
                lang={props.lang}
            />
            <Sponsors />
            <Faqs />
            <Subscribe />

            <Video
                lang={props.lang}
            />

            <Blog
                lang={props.lang}
                noticias={props.noticias}
                ruta={props.ruta}
            />
            <Footer
                lang={props.lang}
                config={props.websgrupo}
                ruta={props.ruta}
            />
            <Scripts
                lang={props.lang}
            />
            {/* </body>*/}
        </>
    );
}

export default Inicio;
