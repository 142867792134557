import React from 'react';
import Header from './Compartido/header.jsx';
import Footer from './Compartido/Footer.jsx';
import Scripts from './Compartido/Scripts.jsx';
import Cabecera from './ListaPublicaciones/Cabecera.jsx';
import EntradasPublicaciones from './ListaPublicaciones/EntradasPublicaciones.jsx';

{/* Bootstrap CSS */ }
import 'bootstrap/dist/css/bootstrap.min.css';
{/* FontAwesome CSS */ }
{/* import "./assets/vendors/font-awesome/css/all.min.css";*/ }
{/*  Slick Slider CSS */ }
import "./../../../../css/web/werespect/slick-slider/slick.css";
{/* Slick Slider Theme */ }
import "./../../../../css/web/werespect/slick-slider/slick-theme.css";
{/*  MetisMenu CSS */ }
import "./../../../../css/web/werespect/metismenu/metismenu.css";
{/* Magnific Popup */ }
import "./../../../../css/web/werespect/magnific-popup/magnific-popup.css";
{/* Main Stylesheet */ }
import "./../../../../css/web/werespect/style.css";
{/* Page Stylesheet */ }
import "./../../../../css/web/werespect/publicaciones.css";
{/* News Stylesheet */ }
import "./../../../../css/web/werespect/listanoticias.css";

const ListaPublicaciones = (props) => {
    return (
        <>
            {/* <body className="body-wrapper home-one" data-scroll-animation="true"> */}
            {/* Overlay */}
            <div className="overlay"></div>

            <Header
                lang={props.lang}
                ruta={props.ruta}
                config={props.websgrupo}
                meta_titulo={props.meta_titulo}
                meta_descripcion={props.meta_descripcion}
                meta_keywords={props.meta_keywords}
            />
            <Cabecera
                lang={props.lang}
            />

            <EntradasPublicaciones
                lang={props.lang}
                ruta={props.ruta}
                noticias={props.noticias}
            />

            <Footer
                lang={props.lang}
                config={props.websgrupo}
                ruta={props.ruta}
            />
            <Scripts
                lang={props.lang}
            />
            {/* </body>*/}
        </>
    );
}

export default ListaPublicaciones;
