import React from "react";
import { useTranslation } from 'react-i18next';

const Cabecera = ({ lang }) => {
    const { t } = useTranslation();

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    return (
        <section className="section-top">
            <div className="container">
                <div className="text-center col-lg-10 offset-lg-1">
                    <div className="section-top-title">
                        <h1>{t("¿Cómo obtener el sello?")}</h1>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default Cabecera;
