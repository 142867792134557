import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Helmet } from "react-helmet";

const Header = ({ lang, ruta, config, meta_titulo, meta_descripcion, meta_keywords }) => {
    const { t } = useTranslation();
    const { i18n } = useTranslation();

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    useEffect(() => {
        document.body.setAttribute('data-scroll-animation', 'true');
    }, []);

    useEffect(() => {
        i18n.changeLanguage(lang)

        if (config.css_personalizado) {
            const linkElement = document.createElement('link');
            linkElement.rel = 'stylesheet';
            linkElement.type = 'text/css';
            linkElement.href = config.css_personalizado;
            const headElement = document.head;
            headElement.appendChild(linkElement);
        }
    }, []);

    const handleLangSelect = (countryCode) => {

        console.log(countryCode);

        i18n.changeLanguage(countryCode);

        let token = document.getElementById('meta_token').getAttribute('content');

        const result = axios.post('/cambiaidioma', {
            lang: countryCode
        }, {
            headers: {
                'content-type': 'text/json',
                'X-CSRF-TOKEN': token
            }
        })
            .then((response) => {
                console.log(response.data);
                console.log("cambiado voy a recargar");
                window.location.reload();
            })
            .catch((error) => {
                console.log("Error");
                console.log(error);
            });
    }

    return (
        <>
            <Helmet>
                <title>{`${meta_titulo}`}</title>
                <meta name="title" content={`${meta_titulo}`} />
                <meta name="description" content={`${meta_descripcion}`} />
                <meta name="keywords" content={`${meta_keywords}`} />
                <meta charset="utf-8" />
                <link rel="icon" type="image/x-icon" href={`${ruta}/web/favicon/favicon.ico`}></link>
            </Helmet>

            {
                (config.activa_analytics === 1) ?
                    <Helmet>
                        <script async src={`https://www.googletagmanager.com/gtag/js?id=${config.codigo_analytics}`}></script>
                        <script>
                            {`
                                            window.dataLayer = window.dataLayer || [];
                                            function gtag(){window && window.dataLayer && window.dataLayer.push(arguments)}
                                            gtag('js', new Date())
                                            gtag('config', '${config.codigo_analytics}');
                                        `}
                        </script>
                    </Helmet>
                    :
                    ""
            }
            {/*-- Top Header Start --*/}
            <div className="srex-top-header d-none d-md-block">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-6 col-md-8">
                            <p>{t("Síguenos en nuestras redes sociales")}</p>
                        </div>
                        <div className="col-6 col-md-4">
                            <ul>
                                <li>
                                    {config.url_facebook && config.url_facebook != "" ?
                                        <a href={config.url_facebook} target="_blank"><i className="fa-brands fa-facebook"></i></a>
                                        :
                                        ""
                                    }
                                    {config.url_instagram && config.url_instagram != "" ?
                                        <a href={config.url_instagram} target="_blank"><i className="fa-brands fa-instagram"></i></a>
                                        :
                                        ""
                                    }
                                    {config.url_twitter && config.url_twitter != "" ?
                                        <a href={config.url_twitter} target="_blank">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{ width: "18px", paddingBottom: "4px" }}>{/*!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--*/}<path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" /></svg>
                                        </a>
                                        :
                                        ""
                                    }
                                    {config.url_linkedin && config.url_linkedin != "" ?
                                        <a href={config.url_linkedin} target="_blank"><i className="fa-brands fa-linkedin"></i></a>
                                        :
                                        ""
                                    }
                                    {config.url_google_plus && config.url_google_plus != "" ?
                                        <a href={config.url_google_plus} target="_blank">
                                            <i className="fa-brands fa-youtube"></i>
                                        </a>
                                        :
                                        ""
                                    }
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* -- Top Header End --*/}
            <header className="srex-header srex-header--home-two">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <nav
                                className="ud-main-menu ud-main-menu--home-one d-flex align-items-center justify-content-between"
                                aria-label="Main Navigation"
                            >
                                <div className="ud-main-menu__logo">
                                    <a href={"/" + preidioma}>
                                        <img
                                            src="/images/web/werespect/logo-werespect.png"
                                            alt="weRespect"
                                            style={{ width: "155px" }}
                                        />

                                        {/*<img
                                            src={ruta + "/web/logo/" + config.logo_fiscal}
                                            alt="WeRespect"
                                        />*/}
                                    </a>
                                </div>
                                <ul className="ud-main-menu__links d-none d-lg-flex">
                                    <li>
                                        <a href={"/" + preidioma}>{t("Inicio")}</a>
                                    </li>
                                    <li>
                                        <a href="#">{t("El sello")}<i className="fa-solid fa-angle-down"></i></a>
                                        <ul className="ud-main-menu__sub-menu">
                                            <li>
                                                <a href={"/" + preidioma + t("como-obtener-el-sello")}>
                                                    {t("Cómo obtener el sello")}
                                                </a>
                                            </li>
                                            <li>
                                                <a href={"/" + preidioma + t("empresas") + "-wrbasic"}>
                                                    WRBasic
                                                </a>
                                            </li>
                                            <li>
                                                <a href={"/" + preidioma + t("empresas") + "-wrplus"}>
                                                    WRPlus
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <a href={"/" + preidioma + t("ventajas")}>{t("Ventajas")}</a>
                                    </li>
                                    <li>
                                        <a href={"/" + preidioma + t("publicaciones")}>{t("Publicaciones")}</a>
                                    </li>
                                    <li>
                                        <a href={"/" + preidioma + t("eventos")}>{t("Eventos")}</a>

                                    </li>
                                    <li>
                                        <a href="#">{t("Empresa")}<i className="fa-solid fa-angle-down"></i></a>
                                        <ul className="ud-main-menu__sub-menu">
                                            <li>
                                                <a href={"/" + preidioma + t("donde-estamos")}>{t("¿Dónde estamos?")}</a>
                                            </li>
                                            <li>
                                                <a href={"/" + preidioma + t("quienes-somos")}>{t("¿Quiénes somos?")}</a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <a href={"/" + preidioma + t("prensa")}>{t("Prensa")}</a>
                                    </li>

                                    <li>
                                        <a href={"/" + preidioma + t("contacto")}>{t("Contacto")}</a>
                                    </li>
                                    <li>
                                        <a>
                                            <img src={"/images/web/werespect/idioma/" + (lang ? lang : "es") + "Flag.png"} /><i className="fa-solid fa-angle-down"></i>
                                        </a>
                                        <ul className="ud-main-menu__sub-menu">
                                            <li>
                                                <a onClick={() => handleLangSelect("ca")} href="#">
                                                    Català
                                                </a>
                                            </li>
                                            <li>
                                                <a onClick={() => handleLangSelect("es")} href="#">
                                                    Español
                                                </a>
                                            </li>
                                            <li>
                                                <a onClick={() => handleLangSelect("en")} href="#">
                                                    English
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>

                                <div className="ud-hamburger-menu d-block d-lg-none ">
                                    <div className="ud-hamburger-menu__btn">
                                        <span></span>
                                    </div>
                                </div>

                                <div
                                    className="gap-2 m-0 srex-header__call srex-header--home-two__call-two d-flex align-items-center d-none d-xl-flex"
                                >
                                    <i className="fa-thin fa-award" style={{ fontSize: '40px', color: '#fff' }}></i>
                                    <div>
                                        <h4>
                                            <a href={"/" + preidioma + t("contacto")}>{t("Alta miembro")}</a>
                                        </h4>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>

                {/* -- Side Popup --*/}
                <div className="ud-side-popup ud-side-popup--home-one">
                    <div className="ud-side-popup__header">
                        <div className="ud-side-popup__header-logo">
                            {/* <img
                                src={ruta + "/web/logo/" + config.logo_fiscal}
                                alt="WeRespect"
                            />*/}
                            <img
                                src="/images/web/werespect/logo-werespect.png"
                                alt="weRespect"
                                style={{ width: "155px" }}
                            />
                        </div>
                        <button className="side-popup-close">
                            <i className="fa-solid fa-xmark"></i>
                        </button>
                    </div>

                    <div className="ud-side-popup__body">
                        <ul className="metismenu" id="side-menu">
                            <li>
                                <a href={"/" + preidioma}>
                                    {t("Inicio")}
                                </a>
                            </li>
                            <li>
                                <a href="#">{t("El sello")}<i className="fa-solid fa-angle-down"></i></a>
                                <ul className="ud-main-menu__sub-menu">
                                    <li>
                                        <a href={"/" + preidioma + t("como-obtener-el-sello")}>
                                            {t("Cómo obtener el sello")}
                                        </a>
                                    </li>
                                    <li>
                                        <a href={"/" + preidioma + t("empresas") + "-wrbasic"}>
                                            wRbasic
                                        </a>
                                    </li>
                                    <li>
                                        <a href={"/" + preidioma + t("empresas") + "-wrplus"}>
                                            wRplus
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a href={"/" + preidioma + t("ventajas")}>{t("Ventajas")}</a>
                            </li>
                            <li>
                                <a href={"/" + preidioma + t("publicaciones")}>{t("Publicaciones")}</a>
                            </li>
                            <li>
                                <a href={"/" + preidioma + t("eventos")}>{t("Eventos")}</a>
                            </li>
                            <li>
                                <a href="#">{t("Empresas")}<i className="fa-solid fa-angle-down"></i></a>
                                <ul className="ud-main-menu__sub-menu">
                                    <li>
                                        <a href={"/" + preidioma + t("donde-estamos")}>{t("¿Dónde estamos?")}</a>
                                    </li>
                                    <li>
                                        <a href={"/" + preidioma + t("quienes-somos")}>{t("¿Quienes somos?")}</a>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a href={"/" + preidioma + t("prensa")}>{t("Prensa")}</a>
                            </li>
                            <li>
                                <a href={"/" + preidioma + t("contacto")}>{t("Contacto")}</a>
                            </li>
                            <li>
                                <a>
                                    <img src={"/images/web/werespect/idioma/" + (lang ? lang : "es") + "Flag.png"} /><i className="fa-solid fa-angle-down"></i>
                                </a>
                                <ul className="ud-main-menu__sub-menu">
                                    <li>
                                        <a onClick={() => handleLangSelect("ca")} href="#">
                                            Català
                                        </a>
                                    </li>
                                    <li>
                                        <a onClick={() => handleLangSelect("es")} href="#">
                                            Español
                                        </a>
                                    </li>
                                    <li>
                                        <a onClick={() => handleLangSelect("en")} href="#">
                                            English
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>

                    <div className="ud-side-popup__bottom">
                        <div className="ud-side-popup__bottom-info">
                            <div
                                className="gap-3 py-0 m-0 ud-side-popup__bottom-info-box d-flex align-items-center"
                            >
                                <i className="fa-solid fa-phone"></i>
                                <div className="ud-header-info-box__details">
                                    <span>{t("Teléfono")}</span>
                                    <h4>
                                        <a href={"tel:" + config.telefono_fiscal}>{config.telefono_fiscal}</a>
                                    </h4>
                                </div>
                            </div>
                            <div
                                className="gap-3 py-0 m-0 ud-side-popup__bottom-info-box d-flex align-items-center"
                            >
                                <i className="fa-solid fa-location-dot"></i>
                                <div className="ud-header-info-box__details">
                                    <span>{t("Dirección")} </span>
                                    <h4>{config.direccion_fiscal + " ," + config.poblacion_fiscal}</h4>
                                </div>
                            </div>
                            <div
                                className="gap-3 py-0 m-0 ud-side-popup__bottom-info-box d-flex align-items-center"
                            >
                                <i className="fa-solid fa-envelope"></i>
                                <div className="ud-header-info-box__details">
                                    <span>{t("Email")}</span>
                                    <h4>
                                        <a href={"mailto:" + config.email_fiscal}>{config.email_fiscal}</a >
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <ul className="ud-side-popup__bottom-socials">
                            <li>
                                {config.url_facebook && config.url_facebook != "" ?
                                    <a href={config.url_facebook} target="_blank"><i className="fa-brands fa-facebook"></i></a>
                                    :
                                    ""
                                }
                            </li>
                            <li>
                                {config.url_twitter && config.url_twitter != "" ?
                                    <a href={config.url_twitter} target="_blank">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{ width: "18px", paddingBottom: "4px" }}>{/*!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--*/}<path fill="white" d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" /></svg>
                                    </a>
                                    :
                                    ""
                                }
                            </li>
                            <li>
                                {config.url_instagram && config.url_instagram != "" ?
                                    <a href={config.url_instagram} target="_blank"><i className="fa-brands fa-instagram"></i></a>
                                    :
                                    ""
                                }
                            </li>
                            <li>
                                {
                                    config.url_google_plus && config.url_google_plus != "" ?
                                        <a href={config.url_google_plus} target="_blank">
                                            <i className="fa-brands fa-youtube"></i>
                                        </a>
                                        :
                                        ""
                                }
                            </li>
                            <li>
                                {config.url_linkedin && config.url_linkedin != "" ?
                                    <a href={config.url_linkedin} target="_blank"><i className="fa-brands fa-linkedin"></i></a>
                                    :
                                    ""
                                }
                            </li>
                        </ul>
                    </div>
                </div>
            </header>
        </>
    )
}

export default Header;
