import { React } from "react";
import { useTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';

const TextoDondeEstamos = ({ ruta, lang, dondeestamos }) => {
    const { t } = useTranslation();

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    var imagen = "/images/web/bedloop-nofoto.png";

    if (dondeestamos.imagen_cabecera) {
        imagen = ruta + "/" + dondeestamos.imagen_cabecera;
    }

    return (
        <>

            <section className="srex-service-two srex-section">
                <div className="container" >
                    <div className="text-center srex-section__head wow ud-fade-in-up" data-wow-delay="200ms">
                        <h5 className="srex-section__head__badge">
                            {t("¿Dónde estamos?")}
                        </h5>
                        <h2 className="srex-section__head__title">
                            {t("Cada vez somos más")}
                        </h2>
                    </div>
                    <div className="srex-info-box">
                        <div className="row">
                            <div className="col-md-6 col-lg-2 col-12">
                            </div>
                            <div className="col-md-6 col-lg-8 col-12">
                                <div className="srex-info-box__item wow ud-fade-in-up" data-wow-delay="400ms">
                                    <div className="srex-info-box__item__desc">
                                        {ReactHtmlParser(dondeestamos.ladesc)}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-2 col-12">
                            </div>
                        </div>
                    </div>

                    <div className="srex-stats srex-stats--two">
                        <div className="container">
                            <div className="srex-stats__items wow ud-fade-in-up" data-wow-delay="200ms">
                                <div className="row">
                                    <div className="col-md-6 col-lg-3 col-12">
                                        <div className="srex-stats__item">
                                            <div className="srex-stats__item__icon">
                                                <i className="fa-thin fa-hand-holding-heart" style={{ fontSize: '40px', color: '#1d8f2c' }}></i>
                                            </div>
                                            <div className="srex-stats__item__text">
                                                <h2><span className="counter">80</span>+</h2>
                                                <h3>{t("Miembros y Partners")}</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-3 col-12">
                                        <div className="srex-stats__item">
                                            <div className="srex-stats__item__icon">
                                                <i className="fa-thin fa-users" style={{ fontSize: '40px', color: '#1d8f2c' }}></i>
                                            </div>
                                            <div className="srex-stats__item__text">
                                                <h2><span className="counter">180</span>+</h2>
                                                <h3>{t("Eventos")}</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-3 col-12">
                                        <div className="srex-stats__item">
                                            <div className="srex-stats__item__icon">
                                                <i className="fa-thin fa-screen-users" style={{ fontSize: '40px', color: '#1d8f2c' }}></i>
                                            </div>
                                            <div className="srex-stats__item__text">
                                                <h2><span className="counter">300</span>+</h2>
                                                <h3>{t("Formaciones")}</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-3 col-12">
                                        <div className="srex-stats__item">
                                            <div className="srex-stats__item__icon">
                                                <i className="fa-thin fa-house-heart" style={{ fontSize: '40px', color: '#1d8f2c' }}></i>
                                            </div>
                                            <div className="srex-stats__item__text">
                                                <h2><span className="counter">390</span>+</h2>
                                                <h3>{t("Empresas Certificadas")}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </>


    );
};
export default TextoDondeEstamos;
