import { React } from "react";
import { useTranslation } from 'react-i18next';

const LogosEmpresas = ({ lang, ruta, empresas, tipo }) => {
    const { t } = useTranslation();

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    return (
        <>
            <section className="srex-support">
                <div className="container">
                    <div className="srex-section__head wow ud-fade-in-up mb-4" data-wow-delay="200ms">
                        <div className="d-flex align-content-center gap-5">
                            <h2 className="srex-section__head__title">
                                {t("Estas empresas cumplen los requisitos del certificado")}:
                            </h2>
                        </div>
                    </div>
                    <div className="row">
                        {tipo === 'wrplus' ?
                            <div className="col-md-6 col-lg-3 col-12">
                                <div className="srex-support__item d-flex align-items-center gap-4 wow ud-fade-in-up" data-wow-delay="200ms">
                                    <i class="fa-regular fa-moon verde45"></i>
                                    <h4 className="font16">{t("Control de incidencias nocturnas")}</h4>
                                </div>
                            </div>
                            : ""
                        }
                        <div div className={tipo === 'wrplus' ? "col-md-6 col-lg-3 col-12" : "col-md-6 col-lg-4 col-12"}>
                            <div className="srex-support__item d-flex align-items-center gap-4 wow ud-fade-in-up" data-wow-delay="400ms">
                                <i class="fa-regular fa-alarm-clock verde45"></i>
                                <h4 className={tipo === 'wrplus' ? "font16" : "font15"}>{t("Control del ruido en los apartamentos")}</h4>
                            </div>
                        </div>
                        <div className={tipo === 'wrplus' ? "col-md-6 col-lg-3 col-12" : "col-md-6 col-lg-4 col-12"}>
                            <div className="srex-support__item d-flex align-items-center gap-4 wow ud-fade-in-up" data-wow-delay="600ms">
                                <i class="fa-regular fa-seedling verde45"></i>
                                <h4 className={tipo === 'wrplus' ? "font16" : "font15"}>{t("Cuidado del medio ambiente")}</h4>
                            </div>
                        </div>
                        <div className={tipo === 'wrplus' ? "col-md-6 col-lg-3 col-12" : "col-md-6 col-lg-4 col-12"}>
                            <div className="srex-support__item d-flex align-items-center gap-4 wow ud-fade-in-up" data-wow-delay="800ms">
                                <i class="fa-regular fa-handshake verde45"></i>
                                <h4 className={tipo === 'wrplus' ? "font16" : "font15"}>{t("Normativa de convivencia")}</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="srex-service">
                <div className="container">
                    <div className="srex-info-box">
                        <div className="row">
                            {empresas.map((unaempresa, index) => (
                                <div className="mb-3 col-md-6 col-lg-3 col-12" key={index}>
                                    <div className="srex-info-box__item wow ud-fade-in-up" data-wow-delay={200 * ((index + 1) % 4) + "ms"}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div>
                                                {unaempresa.logo ?
                                                    <img src={ruta + "/comercios/" + unaempresa.id + "/logo/" + unaempresa.logo}
                                                        alt={unaempresa.nombre} />
                                                    :
                                                    <img src="https://picsum.photos/45/45" alt={unaempresa.nombre} />
                                                }
                                            </div>
                                        </div>
                                        <h3 className="srex-info-box__item__text">
                                            {unaempresa.nombre}
                                        </h3>
                                    </div>
                                </div>
                            ))
                            }
                        </div>
                    </div>
                </div>
            </section>


        </>
    );
}

export default LogosEmpresas;
