import { React, useState } from "react";
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import axios from 'axios';

const Subscribe = () => {
    const { t } = useTranslation();

    const [email, setEmail] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        const url = e.target.action;

        if (email === "") {
            var mensajeerror = "";
            if (email === "") mensajeerror += `${t('Email')}`;
            Swal.fire({
                title: `${t('Error')}`,
                html: `${t('Todos los campos obligatorios')}<br/>` + mensajeerror,
                timer: 2500,
                showConfirmButton: false,
                allowOutsideClick: false,
            });
        }
        else {
            let elmail = email;

            let token = document.getElementById('meta_token').getAttribute('content');
            const result = axios.post(url, {
                contactMessage: elmensaje
            }, {
                headers: {
                    'content-type': 'text/json',
                    'X-CSRF-TOKEN': token
                }
            })
                .then((response) => {
                    console.log(response);
                    if (response.data.type === "error") {
                        Swal.fire({
                            title: "KO!",
                            html: `${t('Contacto no enviado')}`,
                            timer: 1500,
                            showConfirmButton: false,
                            allowOutsideClick: false,
                        });
                    } else {
                        Swal.fire({
                            title: "OK!",
                            html: `${t('Contacto enviado')}`,
                            timer: 1500,
                            showConfirmButton: false,
                            allowOutsideClick: false,
                        }).then((result) => {
                            window.location.reload();
                        });
                    }
                });
        }
    }

    return (
        <>
            <section className="srex-news-letter srex-news-letter--three">
                <div className="container">
                    <div className="srex-news-letter__box wow ud-fade-in-up" data-wow-delay="200ms">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-12">
                                <div className="flex srex-news-letter__box__left align-content-center justify-content-between">
                                    <i className="fa-thin fa-envelope-open-text" style={{ fontSize: '80px', color: '#FFFFFF' }}></i>
                                    <h2>{t("Nuestra newsletter")}</h2>
                                </div>
                            </div>
                            <div className="col-lg-6 col-12">
                                <div className="srex-news-letter__box__right">
                                    <form className="text-center" onSubmit={handleSubmit} action="/enviacontacto">
                                        <input placeholder={t("Dirección de email")} type="email" name="email" id="email" aria-required="true" aria-invalid="false" value={email} onChange={(e) => setEmail(e.target.value)} />
                                        <button type="submit" className="srex-btn srex-btn--white">
                                            {t("Subscribir ahora")} <i className="fa-solid fa-plus"></i>
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="srex-pricing srex-section">

            </section>
        </>


    );
}
export default Subscribe;
