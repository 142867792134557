import React, { useState } from "react";
import { useTranslation } from 'react-i18next';

import Swal from 'sweetalert2';
import axios from 'axios';

const Footer = ({ lang, config, ruta }) => {
    const { t } = useTranslation();

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    var currentTime = new Date();
    var year = currentTime.getFullYear();

    const [email, setEmail] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        const url = e.target.action;

        if (email === "") {
            var mensajeerror = "";
            if (email === "") mensajeerror += `${t('Email')}`;
            Swal.fire({
                title: `${t('Error')}`,
                html: `${t('Todos los campos obligatorios')}<br/>` + mensajeerror,
                timer: 2500,
                showConfirmButton: false,
                allowOutsideClick: false,
            });
        }
        else {
            let elmail = email;

            let token = document.getElementById('meta_token').getAttribute('content');
            const result = axios.post(url, {
                contactMessage: elmensaje
            }, {
                headers: {
                    'content-type': 'text/json',
                    'X-CSRF-TOKEN': token
                }
            })
                .then((response) => {
                    console.log(response);
                    if (response.data.type === "error") {
                        Swal.fire({
                            title: "KO!",
                            html: `${t('Contacto no enviado')}`,
                            timer: 1500,
                            showConfirmButton: false,
                            allowOutsideClick: false,
                        });
                    } else {
                        Swal.fire({
                            title: "OK!",
                            html: `${t('Contacto enviado')}`,
                            timer: 1500,
                            showConfirmButton: false,
                            allowOutsideClick: false,
                        }).then((result) => {
                            window.location.reload();
                        });
                    }
                });
        }
    }

    return (
        <footer className="srex-footer-one">
            <div className="container">
                <div className="row justify-content-between align-items-center srex-footer-one__top">
                    <div className="col-6 col-lg-6 col-md-6">
                        {/*<img
                            src={ruta + "/web/logo/" + config.logo_fiscal}
                            alt="WeRespect"
                        />*/}
                        <img
                            src="/images/web/werespect/logo-werespect.png"
                            alt="weRespect"
                            style={{ width: "155px" }}
                        />
                    </div>
                    <div className="col-lg-6 col-6 col-md-6">
                        <ul className="srex-footer__social_links">
                            {config.url_facebook && config.url_facebook != "" ?
                                <li>
                                    <a href={config.url_facebook}><i className="fa-brands fa-facebook" target="_blank"></i></a>
                                </li>
                                :
                                ''
                            }
                            {config.url_twitter && config.url_twitter != "" ?
                                <li>
                                    <a href={config.url_twitter}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{ width: "18px" }}>{/*!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--*/}<path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" /></svg>
                                    </a>
                                </li>
                                :
                                ''
                            }

                            {config.url_instagram && config.url_instagram != "" ?
                                <li>
                                    <a href={config.url_instagram}><i className="fa-brands fa-instagram" target="_blank"></i></a>
                                </li>
                                :
                                ''
                            }

                            {config.url_pinterest && config.url_pinterest != "" ?
                                <li>
                                    <a href={config.url_pinterest}><i className="fa-brands fa-pinterest" target="_blank"></i></a>
                                </li>
                                :
                                ''
                            }
                        </ul>
                    </div>
                </div>
                <hr />
                <div className="srex-footer__links">
                    <div className="row">
                        <div className="col-12 col-lg-3 col-md-6">
                            <div className="srex-footer__links__wrapper">
                                <h4>{t("Contacta")}</h4>
                                <div className="srex-footer__links__content">
                                    <ul className="srex-footer__contact">
                                        <li>
                                            <div className="srex-footer__contact__icon">
                                                <i className="fa-solid fa-phone"></i>
                                            </div>
                                            <div className="srex-footer__contact__text">
                                                <p>
                                                    <a href={"tel:" + config.telefono_fiscal}>{config.telefono_fiscal}</a>
                                                </p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="srex-footer__contact__icon">
                                                <i className="fa-solid fa-envelope"></i>
                                            </div>
                                            <div className="srex-footer__contact__text">
                                                <p>
                                                    <a href={"mailto:" + config.email_fiscal}>{config.email_fiscal}</a>
                                                </p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-lg-3 col-md-6">
                            <div className="srex-footer__links__wrapper">
                                <h4 style={{ textTransform: "none" }}>weRespect</h4>
                                <div className="srex-footer__links__content">
                                    <ul className="srex-footer__links__list">
                                        <li>
                                            <a href={"/" + preidioma + t("ventajas")}>{t("Ventajas")}</a>
                                        </li>
                                        <li>
                                            <a href={"/" + preidioma + t("como-obtener-el-sello")}>{t("Cómo obtener el Sello")}</a>
                                        </li>
                                        <li>
                                            <a href={"/" + preidioma + t("empresas") + "-wrbasic"}>{t("WR Basic")}</a>
                                        </li>
                                        <li>
                                            <a href={"/" + preidioma + t("empresas") + "-wrplus"}>{t("WR Plus")}</a>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-lg-3 col-md-6">
                            <div className="srex-footer__links__wrapper">
                                <h4>{t("Comunicaciones")}</h4>
                                <div className="srex-footer__links__content">
                                    <ul className="srex-footer__links__list">
                                        <li>
                                            <a href={"/" + preidioma + t("eventos")}>{t("Eventos")}</a>
                                        </li>
                                        <li>
                                            <a href={"/" + preidioma + t("prensa")}>{t("Prensa")}</a>
                                        </li>
                                        <li>
                                            <a href={"/" + preidioma + t("publicaciones")}>{t("Publicaciones")}</a>
                                        </li>
                                        <li>
                                            <a href={"/" + preidioma + t("contacto")}>{t("Contacto")}</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-3 col-md-6">
                            <div className="srex-footer__links__wrapper">
                                <h4>{t("Subscríbete")}</h4>
                                <div className="srex-footer__links__content">
                                    <div className="srex-footer__newsletter">
                                        <p>
                                            {t("Subscríbete a nuestra newsletter")}
                                        </p>
                                        <div className="srex-news-letter__box__right">
                                            <form className="text-center" onSubmit={handleSubmit} action="/enviacontacto">
                                                <input placeholder={t("Dirección de email")} type="email" name="email" id="email" aria-required="true" aria-invalid="false" value={email} onChange={(e) => setEmail(e.target.value)} />
                                                <button type="submit" className="srex-btn srex-btn--primary">
                                                    {t("Subscríbete")}
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <hr />

                <div className="srex-footer__bottom">
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <p>
                                &copy; {year} weRespect | {t("Hecho con")}  <i className="fa-solid fa-heart" style={{ color: "red" }}></i> {t("en")} <a target="_blank" rel="nofollow" href="https://www.bedloop.com?utm_source=sponsor&utm_medium=web&utm_campaign=werespect&utm_id=werespect" style={{ textDecoration: "none", fontSize: "15px" }}>BedLoop</a>
                            </p>
                        </div>
                        <div className="col-12 col-md-6">
                            <ul className="srex-footer__bottom__links">
                                <li>
                                    <a href={"/" + preidioma + t("cookies")}>{t("Política de cookies")}</a>
                                </li>
                                <li>
                                    <a href={"/" + preidioma + t("politica-de-privacidad")}>{t("Política de privacidad")}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;
