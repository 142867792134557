import { React } from "react";
import { useTranslation } from 'react-i18next';

const AboutUs = ({ lang }) => {
    const { t } = useTranslation();

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    return (
        <section className="mt-5 srex-about-us-two srex-section">
            <div className="container">
                <div className="row justify-content-between">
                    <div className="col-12 col-lg-8">
                        <div className="srex-section__head wow ud-fade-in-up" data-wow-delay="200ms">
                            <h5 className="srex-section__head__badge" style={{ textTransform: "none" }}>
                                weRespect
                            </h5>
                            <div className="gap-5 d-flex align-content-center">
                                <h2 className="srex-section__head__title">
                                    {t("El primer sello internacional de sostenibilidad")}
                                </h2>
                                <img
                                    src="/images/web/werespect/werespect.png"
                                    alt="About_Us_Frame"
                                    className="srex-about-us-two__img wow ud-fade-in-down" data-wow-delay="200ms"
                                    style={{ width: "117px" }}
                                />
                            </div>
                        </div>
                        <div className="srex-about-us-two__left-content wow ud-fade-in-up" data-wow-delay="200ms">
                            <div className="srex-about-us-two__left-content__img">
                                <img
                                    src="/images/web/werespect/sostenibilidad-1.jpg"
                                    alt="About_Us_Left"
                                />
                            </div>
                            <div className="srex-about-us-two__left-content__right wow ud-fade-in-up" data-wow-delay="200ms">
                                <p>{t("weRespect promueve y educa sobre la sostenibilidad en el entorno construido. Actualmente, weRespect cuenta con alrededor de 150 miembros que representan a miles de personas interesadas e involucradas en la edificación sostenible.")}</p>
                                <div className="mt-3 srex-icon-list">
                                    <ul>
                                        <li>
                                            <i className="fa-solid fa-check"></i>
                                            <p>
                                                {t("Descuentos en varios programas, como los relacionados con la educación.")}
                                            </p>
                                        </li>
                                        <li>
                                            <i className="fa-solid fa-check"></i>
                                            <p>{t("Formación y eventos sobre construcción sostenible.")}</p>
                                        </li>
                                    </ul>
                                </div>
                                <a href={"/" + preidioma + t("como-obtener-el-sello")} className="mt-4 srex-btn srex-btn--primary">
                                    {t("Más Información")}<i className="fa-solid fa-plus"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4">
                        <img src="/images/web/werespect/sostenibilidad-2.jpg" alt="About_Us_Right" className="srex-about-us-two__right-content__img wow ud-fade-in-up" data-wow-delay="200ms" />
                        <div className="gap-3 srex-about-us-two__box d-flex wow ud-fade-in-up" data-wow-delay="200ms">
                            <img
                                src="/images/web/werespect/medal.png"
                                alt="weRespect" />
                            <div className="srex-about-us-two__box__text">
                                <h2 style={{ textTransform: "none" }}>weRespect</h2>
                                <p>{t("Comprometidas con el cambio sostenible del sector.")}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default AboutUs;
