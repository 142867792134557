import { React } from "react";
import { useTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';

const TextoPolitica = ({ ruta, lang, politicaprivacidad }) => {
    const { t } = useTranslation();

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    var imagen = "/images/web/bedloop-nofoto.png";

    if (politicaprivacidad.imagen_cabecera) {
        imagen = ruta + "/" + politicaprivacidad.imagen_cabecera;
    }

    return (
        <section className="srex-single-project srex-section">
            <div className="container">
                <div className="row">
                    <div class="col-lg-12">
                        <div class="srex-single-pro-img">
                            <img src={imagen} alt="" />
                        </div>
                    </div>
                    <div className="col-lg-12 col-sm-12 col-xs-12">
                        <div className="single_pro_details">
                            {ReactHtmlParser(politicaprivacidad.ladesc)}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default TextoPolitica;
