import { useTranslation } from 'react-i18next';

import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker } from 'react-leaflet';

import axios from "axios";

const MapaContacto = ({ lang, config }) => {
    const { t } = useTranslation();

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    const [address, setAddress] = useState(config.direccion_fiscal + "," + config.codigo_postal_fiscal + "," + config.poblacion_fiscal);
    const [address2, setAddress2] = useState(config.poblacion_fiscal);
    const [coordinates, setCoordinates] = useState([]);

    useEffect(() => {
        if (address === "") {
            return;
        }

        axios
            .get(`https://nominatim.openstreetmap.org/search?format=json&q=${address}`)
            .then((res) => {
                if (res.data.length > 0) {
                    setCoordinates([res.data[0].lat, res.data[0].lon]);
                }
                else {
                    axios
                        .get(`https://nominatim.openstreetmap.org/search?format=json&q=${address2}`)
                        .then((res) => {
                            if (res.data.length > 0) {
                                setCoordinates([res.data[0].lat, res.data[0].lon]);
                            }
                        })
                        .catch((err) => {
                            console.error(err);
                        });
                }
            })
            .catch((err) => {
                console.error(err);
            });

    }, [address, address2]);

    return (
        <div className="map_area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="map">
                            {coordinates.length > 0 && (
                                <MapContainer center={coordinates} zoom={16} scrollWheelZoom={false}>
                                    <TileLayer
                                        attribution='Imagery &copy; <a href="https://www.mapbox.com/">Mapbox</a>'
                                        url="https://api.mapbox.com/styles/v1/magnoapartments/clm9hgl2p015701pjd18p6vrv/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoibWFnbm9hcGFydG1lbnRzIiwiYSI6ImNsY29uNmx4bjAwMWszdm4yYmY5aDR5YngifQ.6pEwCqEnsjUoXQQxu-Tcng"
                                    />
                                    <Marker position={coordinates}>
                                    </Marker>
                                </MapContainer>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default MapaContacto;
