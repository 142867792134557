import { React } from "react";
import { useTranslation } from 'react-i18next';

const Cabecera = ({ lang }) => {
    const { t } = useTranslation();

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    return (
        <section className="srex-hero-two srex-section">
            <div className="container">
                <div className="srex-hero-two__box d-flex flex-column">
                    <h1 className="wow ud-fade-in-up" data-wow-delay="200ms">
                    </h1>
                    <h2 className="wow ud-fade-in-up" data-wow-delay="200ms">{t("Convivencia")}</h2>
                    <p className="wow ud-fade-in-up" data-wow-delay="200ms">{t("El sello de los apartamentos turísticos que están comprometidos con la convivencia y el impacto del turismo en la ciudad")}</p>
                    <div className="gap-4 d-flex wow ud-fade-in-up" data-wow-delay="200ms">
                        <a href={"/" + preidioma + t("como-obtener-el-sello")} className="srex-btn srex-btn--secondary">
                            {t("Cómo obtener el sello")} <i className="fa-solid fa-plus"></i>
                        </a>
                        <a href={"/" + preidioma + t("contacto")} className="srex-btn srex-btn--outline">
                            {t("Alta como miembro")}  <i className="fa-solid fa-plus"></i>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Cabecera;
