import { React } from "react";
import { useTranslation } from 'react-i18next';

const Cabecera = ({ lang, noticia }) => {
    const { t } = useTranslation();

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    return (
        <section className="section-top">
            <div className="container">
                <div className="text-center col-lg-10 offset-lg-1">
                    <div className="section-top-title">
                        <h1>{noticia.eltitulo}</h1>
                        <ul>
                            <li><a href={"/" + preidioma}>{t("inicio")}</a></li>
                            <li> {"> " + t("publicacion")}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default Cabecera;

